// import './App.css';
// import MainContent from './Pages/MainContent';
// import { Route, Routes } from "react-router-dom";
// import { Transaction } from './Components/Transaction/Transaction';
// import { Incooming } from './Components/Incomming/Incooming';
// import { Withdrawal } from './Components/Withdrawal/Withdrawal';
// // import { Incooming } from './Pages/Incooming';
// // import { Withdrawal } from './Pages/Withdrawal';
// import { Login } from './Pages/Login';
// import SideBar from './Components/Layout/SideBar';
// import TopBar from './Components/Layout/TopBar';
// import { Footer } from './Components/Layout/Footer';
// import TransactionList from './Components/Transaction/TransactionList';
// import IncommingList from './Components/Incomming/IncommingList';
// import WithdrawalList from './Components/Withdrawal/WithdrawalList';

// function App() {
//   return (
//     <div className='header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed bg-gray-00 '>
//       <Routes>
//         <Route path="/" element={<Login />} />
//         <Route path="/*" element={
//           <div className="d-flex flex-column flex-root min-vh-100">
//             <div className="page d-flex flex-row flex-column-fluid">
//               <SideBar />
//               <div className="d-flex flex-column flex-row-fluid">
//                 <TopBar />
//                 <Routes>
//                   <Route path="/dashboard" element={<MainContent />} />
//                   <Route path="/transaction" element={<Transaction />} />
//                   <Route path="/incomming" element={<Incooming />} />
//                   <Route path="/withdrawal" element={<Withdrawal />} />
//                   <Route path="/transactionlist" element={<TransactionList />} />
//                   <Route path="/incomminglist" element={<IncommingList />} />
//                   <Route path="/withdrawallist" element={<WithdrawalList />} />

//                   <Route path="/editTransaction/:id" element={<Transaction />} />
//                   <Route path="/editIncooming/:id" element={<Incooming />} />
//                   <Route path="/editWithdrawal/:id" element={<Withdrawal />} />
//                 </Routes>
//               </div>
//             </div>
//             <Footer />
//           </div>
//         } />
//       </Routes>
//     </div>
//   );
// }

// export default App;

import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Transaction } from "./Components/Transaction/Transaction";
import { Incooming } from "./Components/Incomming/Incooming";
import { Withdrawal } from "./Components/Withdrawal/Withdrawal";
import { Login } from "./Pages/Login";
import SideBar from "./Components/Layout/SideBar";
import TopBar from "./Components/Layout/TopBar";
import { Footer } from "./Components/Layout/Footer";
import TransactionList from "./Components/Transaction/TransactionList";
import IncommingList from "./Components/Incomming/IncommingList";
import WithdrawalList from "./Components/Withdrawal/WithdrawalList";
import PrivateRoute from "./Components/PrivateRoute"; // Import PrivateRoute
import Unauthorized from "./Components/Layout/Unauthorized";
import AllTransaction from "./Components/Transaction/AllTransaction";
import CreditDebit from "./Components/CreditDebit/CreditDebit";
import CreditList from "./Components/CreditDebit/CreditList";
import DebitList from "./Components/CreditDebit/DebitList";
import ChartsJS from "./Components/Charts/ChartsJS";
import Dashboard from "./Pages/Dashboard";
import { AppContext } from "./context/AppContext";
import { useContext, useEffect } from "react";

function App() {
  useEffect(() => {
    if (window.KTMenu) window.KTMenu.init();
    if (window.KTDrawer) window.KTDrawer.createInstances();
}, []);
  return (
    <div className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed bg-gray-00">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/*"
          element={
            <PrivateRoute routeName="transaction">
              {" "}
              {/* General route for wrapper */}
              <div className="d-flex flex-column flex-root min-vh-100">
                <div className="page d-flex flex-row flex-column-fluid">
                  <SideBar />
                  <div className="d-flex flex-column flex-row-fluid">
                    <TopBar />
                    <div
                      className="wrapper d-flex flex-column bg-gray-300 flex-row-fluid"
                      id="kt_wrapper"
                    >
                      <Routes>
                        <Route
                          path="/dashboard"
                          element={
                            <PrivateRoute routeName="dashboard">
                              <Dashboard />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/AllTransaction"
                          element={
                            <PrivateRoute routeName="AllTransaction">
                              <AllTransaction />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/chartjs"
                          element={
                            <PrivateRoute routeName="chartjs">
                              <ChartsJS />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/transaction"
                          element={
                            <PrivateRoute routeName="transaction">
                              <Transaction />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/incomming"
                          element={
                            <PrivateRoute routeName="incomming">
                              <Incooming />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/withdrawal"
                          element={
                            <PrivateRoute routeName="withdrawal">
                              <Withdrawal />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/creditdebit"
                          element={
                            <PrivateRoute routeName="creditdebit">
                              <CreditDebit />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/transactionlist"
                          element={
                            <PrivateRoute routeName="transactionlist">
                              <TransactionList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/incomminglist"
                          element={
                            <PrivateRoute routeName="incomminglist">
                              <IncommingList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/withdrawallist"
                          element={
                            <PrivateRoute routeName="withdrawallist">
                              <WithdrawalList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/creditlist"
                          element={
                            <PrivateRoute routeName="creditlist">
                              <CreditList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/debitlist"
                          element={
                            <PrivateRoute routeName="debitlist">
                              <DebitList />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/editTransaction/:id"
                          element={<Transaction />}
                        />
                        <Route
                          path="/editIncooming/:id"
                          element={<Incooming />}
                        />
                        <Route
                          path="/editWithdrawal/:id"
                          element={<Withdrawal />}
                        />
                        <Route
                          path="/creditdebit/:id"
                          element={<CreditDebit />}
                        />
                        <Route
                          path="/unauthorized"
                          element={<Unauthorized />}
                        />
                      </Routes>
                      <Footer />
                    </div>
                  </div>
                </div>
              </div>
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
