import { jwtDecode } from "jwt-decode";
import toast from 'react-hot-toast';
import React, { useContext, useState } from 'react';
import {  useNavigate } from 'react-router';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import '../assets/css/style.bundle.css'
import { AppContext } from '../context/AppContext';
import {  TextField, Button,  Label } from '../Components/UI/FormComponent';

export function Login() {

    const navigate = useNavigate();
    const [ data,setData] = useState({});
    const { token , handleSetToken , setManueList ,PieChartData } = useContext(AppContext);
    const [ShowPassword, setShowPassword] = useState(false);
    const [user, setUser] = useState({
        email: "",
        password: "",
        apikey: "Unique@1234567890@"
    });



    function ChangeHandler(event) {
        setUser((prev) => (
            {
                ...prev, [event.target.name]: event.target.value
            }
        ))
    }

    const submitHandler = async (event, res, req) => {
        event.preventDefault();

        try {
            const response = await fetch("https://uniquehisab.theuniqueitsolution.com/Server/Api/login.php", {
                method: "POST",
                body: JSON.stringify(user),
            });

            const res_data = await response.json();
            setData(res_data.data);

            if (res_data.status)
            {
                handleSetToken(res_data.data.token);
                const decodedToken = jwtDecode(res_data.data.token);
              
                setManueList(decodedToken.manue || []);
                PieChartData({
                    token: res_data.data.token,
                    apikey: "Unique@1234567890@",
                    Month: "01",
                    Year: "2025",
                })
                toast.success("Login successful 🎉 ! Welcome back!", {
                    position: "top-right",
                    style: {
                      fontSize : '13px',
                      letterSpacing: '1px',
                      fontWeight: '500',
                      borderRadius: '8px',
                    },
                  }); 
                navigate('/dashboard');
            }
            else
            {
                toast.error("Authentication failed. Please check your credentials and try again.", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                
            }
        } 
        catch (error) 
        {
            console.error("Network error:", error,data);
        }
    }
    // console.log(token);

    return (
        <div>
            <div className="d-flex flex-column flex-root min-vh-100">
                <div className="d-flex flex-column flex-column-fluid  position-x-center bgi-no-repeat  bgi-attachment-fixed"
                    style={{ backgroundColor: '#1c2c3c' }}>
                    <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                        <span className="mb-12">
                            <img alt="Logo" src="https://cqpplefitting.com/ad_cqpple/Admin/assets/LOGO/Png_UniqueIT-min.png" style={{ height: "7s0px", width: "250px " }} />
                        </span>
                        <div className="w-lg-500px  rounded shadow-lg p-10 p-lg-15 mx-auto">
                            <form onSubmit={submitHandler} className="form w-100" id="kt_sign_in_form">
                                <div className="fv-row mb-8">
                                    <Label className="text-light" labelText="Email" />
                                    <TextField
                                        type="email"
                                        id='email'
                                        name='email'
                                        value={user.email}
                                        onChange={ChangeHandler}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder="email@gmail.com"
                                    />
                                </div>
                                <div className="fv-row mb-10 position-relative">
                                    <Label className="text-light" labelText="Password" />
                                    <TextField
                                        type={ShowPassword ? ("text") : ("password")}
                                        id='password'
                                        name='password'
                                        value={user.password}
                                        onChange={ChangeHandler}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder="*****"
                                    />
                                    <span className='position-absolute right-3 top-[38px] cursor-pointer' style={{ right: "3%", color: "black", top: "48%" }} onClick={() => setShowPassword((prev) => !prev)}>
                                        {
                                            ShowPassword ? (<AiOutlineEyeInvisible fontSize={24} fill='#afb2bf' />) : (<AiOutlineEye fontSize={24} fill='#afb2bf' />)
                                        }
                                    </span>
                                </div>
                                <div className="text-center">
                                    <Button
                                        type="submit"
                                        id="kt_sign_in_submit"
                                        className="btn btn-lg btn-primary w-100 mb-5 indicator-label"
                                        buttonText="Continue"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
