import React, { useContext, useEffect, useState } from 'react';
import '../assets/css/style.bundle.css';
import Charts from '../Components/Charts/Charts';
import { AppContext } from '../context/AppContext';
import { FinancialCard } from '../Components/UI/FinancialCard';
import LineChart from '../Components/Charts/LineChart';
import PieChart from '../Components/Charts/PieChart';
import { Footer } from '../Components/Layout/Footer';

function Dashboard() {
    const { token, pieChart, currentMoney, PieChartData } = useContext(AppContext);
    const [pieData, setPieData] = useState({
        token: token,
        apikey: "Unique@1234567890@",
        Month: "01",
        Year: "2025"
    });

    const icons = {
        'cash': 'bi bi-cash-stack',
        'Yas Bank UIT': 'bi bi-bank2',
        'Yes Bank UIS': 'bi bi-bank'
    };

    const handleMonthChange = (event) => {
        setPieData((prev) => ({
            ...prev, [event.target.name]: event.target.value,
        }));
        // PieChartData(pieData);
    }

    const handleYearchange = (event) => {
        setPieData((prev) => ({
            ...prev, [event.target.name]: event.target.value,
        }));
        // PieChartData(pieData);
    }
    useEffect(() => {
        console.log(pieData);
        PieChartData(pieData);
    }, [pieData]);

    return (
            <div id="kt_content_container" className="container-xxl my-auto">
                <div className="d-flex flex-column justify-content-between pb-5 mb-5">
                    <div className="row mb-5">
                        <div className="col-lg-6 col-md-12">
                            <div className="fs-2 fw-bolder mb-5">Monthly Financial Overview :</div>
                            <LineChart />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="py-2 px-2">
                                <div className="fs-2 fw-bolder mb-2">Monthly Expense :</div>
                                <div className="d-flex justify-content-between">
                                    <select onChange={handleMonthChange} name='Month' value={pieData.Month} className="form-select rounded-5 me-2" aria-label="Month select">
                                        <option disabled>Select Month</option>
                                        {[...Array(12)].map((_, index) => (
                                            <option key={index} value={String(index + 1).padStart(2, '0')}>{new Date(0, index).toLocaleString('en', { month: 'long' })}</option>
                                        ))}
                                    </select>
                                    <select onChange={handleYearchange} name='Year' value={pieData.Year} className="form-select rounded-5" aria-label="Year select">
                                        <option disabled>Select Year</option>
                                        {[2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033].map(year => (
                                            <option key={year} value={year}>{year}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <PieChart />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <p className="fs-2 fw-bolder mb-0">Financial Overview :</p>
                            <div className="row">
                                {currentMoney.map((item, index) => (
                                    <div className="col-6 mb-3" key={index}>
                                        <FinancialCard
                                            icon={icons[item.Name] || 'bi bi-wallet-fill'}
                                            titleName={item.Name}
                                            amount={item.Current.toLocaleString()}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-12">
                            <div className="fs-2 fw-bolder mb-5">Overall Total Month Expense -</div>
                            
                        </div> */}
                    </div>
                </div>
            </div>

    );
}

export default Dashboard;
