import React, { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { TopbarCard } from '../UI/FinancialCard';
import './topsidebar.css'
import { AmountCard } from './AmountCard';

function TopBar() {
    // Extract values from AppContext
    const { total = 0, currentMoney, creditDebit } = useContext(AppContext);

    return (
        <div id="kt_header" className="header align-items-stretch">
            <div className="container-fluid d-flex flex-row align-items-stretch justify-content-between">
                {/* Mobile Menu Toggle */}
                <div
                    className="d-flex align-items-center d-lg-none ms-n2 me-2"
                    title="Show aside menu"
                >
                    <div
                        className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                        id="kt_aside_mobile_toggle"
                    >
                        <span className="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                    fill="black"
                                />
                                <path
                                    opacity="0.3"
                                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                    fill="black"
                                />
                            </svg>
                        </span>
                    </div>
                </div>

                {/* Logo Section */}
                <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                    <a href="/" className="d-lg-none">
                        <img
                            alt="Logo"
                            src="https://cqpplefitting.com/ad_cqpple/Admin/assets/LOGO/Png_UniqueIT-min.png"
                            className="h-30px"
                        />
                    </a>
                </div>
                <TopbarCard
                    name='Total'
                    amount={total}
                    className={`${total <= 100000 ? 'text-danger' : 'text-primary'}`}
                />

                {/* Financial Overview Button */}
                <div
                    className="d-flex justify-content-end align-items-center"
                    style={{ flex: 1, marginLeft: 'auto' }}
                >
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                            borderRadius: '8px',
                        }}
                    >
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                                width: '50px',
                                height: '50px',
                                backgroundColor: '#eaf4ff',
                                borderRadius: '50%',
                                marginRight: '12px',
                            }}
                        >
                            <i
                                className="bi bi-bar-chart-fill text-primary"
                                style={{ fontSize: '1.5rem' }}
                            ></i>
                        </div>
                        <button
                            className="btn btn-primary"
                            style={{
                                fontSize: '1rem',
                                fontWeight: '600',
                                padding: '12px 24px',
                                borderRadius: '6px',
                            }}
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasWithBothOptions"
                            aria-controls="offcanvasWithBothOptions"
                        >
                            View Financial Overview
                        </button>
                    </div>
                </div>

                {/* Offcanvas Sidebar */}
                <div
                    className="offcanvas offcanvas-end"
                    data-bs-scroll="true"
                    tabIndex="-1"
                    id="offcanvasWithBothOptions"
                    aria-labelledby="offcanvasWithBothOptionsLabel"
                >
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title py-2" id="offcanvasWithBothOptionsLabel">
                            Account Overview
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="offcanvas-body">
                    <AmountCard
                    name='Total'
                    amount={total}
                    className={`${total <= 100000 ? 'text-danger' : 'text-primary'}`}
                />
                        {currentMoney.map((item, index) => (
                            < AmountCard
                                key={index}
                                name={item.Name}
                                amount={item.Current}
                                className={`${item.Current <= 50000.00 ? 'text-danger' : 'text-primary'}`}
                            />
                        ))}
                        {creditDebit.map((item, index) => (
                    <>
                        {Object.entries(item).map(([key, value], idx) => (
                            <AmountCard
                                key={`credit-debit-${index}-${idx}`}
                                name={key}
                                amount={value}
                                className={'text-success'}
                            />
                        ))}
                    </>
                ))}
                        <div className="summary-section">
                            <h4>Accounting Management System</h4>
                        </div>
                        <div className="tagline">
                            "Empowering your financial journey, one step at a time."
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;
