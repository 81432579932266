import React from 'react'
import ApexCharts from 'react-apexcharts';


export function TopbarCard({ key, name, amount , className }) {
    return (
        <div key={key} className='d-flex align-items-stretch align-center'>
            <div className='header-menu align-items-stretch' id='kt_header_nav'>
                <div className='menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700  menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch'
                    data-kt-menu='true'>
                    <div className='menu-item menu-lg-down-accordion me-lg-1'>
                        <p className='fw-bolder fs-2 text-capitalize'>{name} :</p>
                        <p className={`ms-2 mt-1 fs-3 fs-41 fw-bolder p-0 ${className}  `} style={{ color: '#007bff' }}>{amount}</p>
                    </div>
                   
                </div>
            </div>
        </div>
    )
}


export function FinancialCard({  icon, titleName, amount }) {

    const optionss = {
        series: [{
            data: [25, 66, 41, 89, 63, 30, 50]
        }],
        chart: {
            type: 'line',
            width: 100,
            height: 35,
            sparkline: {
                enabled: true
            }
        },
        theme: {
            mode: document.body.classList.contains('dark') ? 'dark' : 'light',
        },
        // colors: [chartColor], // Example color blue
        stroke: {
            width: 4,
            curve: 'smooth',
        },
        tooltip: {
            fixed: {
                enabled: false
            },
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function () {
                        return '';
                    }
                }
            },
            marker: {
                show: false
            }
        }
    };

    const seriess = [{
        data: [25, 66, 41, 89, 63, 30, 50]
    }];

    return (
        <div className="col-12 col-md-12 h-100 col-lg-12">
            <div className="card shadow-sm border-0 h-100">
                <div className="card-body  p-4 justify-content-center">

                    <div className="d-flex align-items-center ">
                        <div className="text-primary mt-2 mb-3">
                            <i className={`${icon} fs-1 text-black`}></i>
                        </div>
                        <div className="ms-auto dropdown mb-3">
                            <button className="btn btn-sm text-secondary dropdown-toggle" data-bs-toggle="dropdown">
                                <i className="bi bi-three-dots"></i>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li><span className="dropdown-item" >View Detail</span></li>
                                <li><sapn className="dropdown-item" >Download</sapn></li>
                            </ul>
                        </div>
                    </div>

                    <h5 className="card-title text-capitalize fs-2 mt-5">{titleName}</h5>
                    <div className="d-flex align-items-center mb-3">
                        <div className=" fw-bold">{amount}</div>
                        <div className="ms-auto" id="total-orders">
                            <ApexCharts
                                options={optionss}
                                series={seriess}
                                type="line"
                                height={35}
                                width={100}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
