import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';




function Sidebar() {

    useEffect(() => {
        // Ensure Metronic drawer functionality is reinitialized
        if (window.KTDrawer) {
            window.KTDrawer.createInstances();
        }
    }, []);

    const IconMapper = {
        "Dashboard": (
            <span className="menu-icon">
                <span className='svg-icon svg-icon-2'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16'
                        fill='currentColor' className='bi bi-speedometer2' style={{ color: "white" }} viewBox='0 0 16 16'>
                        <path
                            d='M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z' />
                        <path fillRule='evenodd'
                            d='M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z' />
                    </svg>
                </span>
            </span>
        ),
        "Add Outgoing": (
            <span className='menu-icon'>
                <span className='svg-icon svg-icon-2'>
                    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"
                        style={{
                            filRrule: "evenodd",
                            clipRule: "evenodd",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeMiterlimit: "2"
                        }}>
                        <path style={{ fill: "none" }} d="M0 0h64v64H0z" />
                        <path
                            d="M53.5 17.365c0-.754-.611-1.365-1.365-1.365h-29.27c-.754 0-1.365.611-1.365 1.365v36.223a1.366 1.366 0 0 0 2.068 1.171l4-2.4a1.366 1.366 0 0 1 1.24-.084l8.11 3.476c.369.158.789.145 1.148-.034l6.823-3.412a1.368 1.368 0 0 1 1.222 0l5.413 2.707a1.365 1.365 0 0 0 1.976-1.221V17.365z"
                            style={{
                                fill: "none",
                                stroke: "#ffffff",
                                strokeWidth: "4px"
                            }} />
                        <path
                            d="m21.5 45-1.692-.725a1.366 1.366 0 0 0-1.24.084l-4 2.4a1.366 1.366 0 0 1-2.068-1.171V9.365c0-.754.611-1.365 1.365-1.365h29.27c.754 0 1.365.611 1.365 1.365V12"
                            style={{
                                fill: "none",
                                stroke: "#ffffff",
                                strokeWidth: "4px"
                            }} />
                        <path
                            d="M40.524 20.713c1.98 1.499 1.966 4.859-.032 7.5-1.997 2.641-5.227 3.569-7.208 2.07-1.981-1.498-1.967-4.859.031-7.5s5.228-3.568 7.209-2.07z"
                            style={{
                                fill: "none",
                                stroke: "#ffffff",
                                strokeWidth: "4px"
                            }} />
                        <path
                            d="M33.284 30.283s.804-3.211 3.717-4.084c2.912-.874 3.102-5.758 3.102-5.758M32 37h11M32 42h11M32 47h11"
                            style={{
                                fill: "none",
                                stroke: "#ffffff",
                                strokeWidth: "4px"
                            }} />
                    </svg>
                </span>
            </span>
        ),
        "Add Incomming": (
            <span className='menu-icon'>
                <span className='svg-icon svg-icon-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <defs>
                        </defs>
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="layer_1-2" data-name="layer 1">
                                <path className="cls-1"
                                    d="M20 48c-9.63 0-20-4.7-20-15 0-6.86 8-18.46 12.23-23.63l1.54 1.26C9 16.42 2 27.16 2 33c0 8.93 9.33 13 18 13 7.93 0 16.92-3.6 17.91-11.51l2 .25C38.76 43.85 28.78 48 20 48zM29.6 15.09a83.386 83.386 0 0 0-3.37-4.46l1.54-1.26c1.12 1.36 2.31 2.94 3.47 4.57z"
                                    style={{
                                        fill: "none",
                                        stroke: "#ffffff",
                                        strokeWidth: "2px"
                                    }} />
                                <path className="cls-1"
                                    d="M20 43c-9 0-14-3.55-14-10 0-4.67 4.52-14.15 8.23-18.64l1.54 1.28C12.35 19.79 8 28.79 8 33c0 6.61 6.53 8 12 8zM20 12c-2.95 0-5.91-.37-7.43-1.11a1 1 0 0 1-.52-.58l-3-9A1 1 0 0 1 10.52.14c4.07 2.46 14.89 2.46 19 0a1 1 0 0 1 1.15.08 1 1 0 0 1 .33 1.1l-3 9a1 1 0 0 1-.52.58C25.91 11.64 23 12 20 12zm-6.19-2.74c2.72 1 9.66 1 12.38 0l2.15-6.43a30.5 30.5 0 0 1-16.68 0zM36 31a4 4 0 0 1-4-4h2a2 2 0 1 0 2-2 4 4 0 1 1 4-4h-2a2 2 0 1 0-2 2 4 4 0 0 1 0 8z"
                                    style={{
                                        fill: "none",
                                        stroke: "#ffffff",
                                        strokeWidth: "2px"
                                    }} />
                                <path className="cls-1"
                                    d="M35 30h2v3h-2zM35 15h2v3h-2zM42 23h2v2h-2zM28 23h2v2h-2z"
                                    style={{
                                        fill: "none",
                                        stroke: "#ffffff",
                                        strokeWidth: "2px"
                                    }} />
                                <path className="cls-1"
                                    d="M25 41a.89.89 0 0 1-.36-.07A1 1 0 0 1 24 40V24a12 12 0 1 1 7 10.92l-5.28 5.76A1 1 0 0 1 25 41zm11-27a10 10 0 0 0-10 10v13.43L30.05 33a1 1 0 0 1 1.21-.2A10 10 0 1 0 36 14z"
                                    style={{
                                        fill: "none",
                                        stroke: "#ffffff",
                                        strokeWidth: "2px"
                                    }} />
                            </g>
                        </g>
                    </svg>

                </span>
            </span>
        ),
        "Add Withdrawal": (
            <span className="menu-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98 98">
                    <rect x="26" y="64" width="28" height="8" rx="1" ry="1"
                        style={{ fill: "#ffffffe8" }} />
                    <path
                        d="M29.79 62H59v-5.51A11.108 11.108 0 0 0 62.81 50H41.65A10.036 10.036 0 0 0 34 45.05V34a5 5 0 0 0-10 0v17.24a11.046 11.046 0 0 0 2.72 7.25zM86 6H2a1.003 1.003 0 0 0-1 1v18a1.003 1.003 0 0 0 1 1h10v-6h-2a4 4 0 0 1 0-8h68a4 4 0 0 1 0 8h-2v6h10a1.003 1.003 0 0 0 1-1V7a1.003 1.003 0 0 0-1-1z"
                        style={{ fill: "#ffffffe8" }} />
                    <path
                        d="M8 16a2.006 2.006 0 0 0 2 2h2v-4h-2a2.006 2.006 0 0 0-2 2zM80 16a2.006 2.006 0 0 0-2-2h-2v4h2a2.006 2.006 0 0 0 2-2zM46 32.68a1.312 1.312 0 0 0-.91-1.26l-.09-.03v2.57a1.32 1.32 0 0 0 1-1.28zM43 26.04a1.323 1.323 0 0 0-.09 2.54l.09.03z"
                        style={{ fill: "#ffffffe8" }} />
                    <path
                        d="M29 27a7.008 7.008 0 0 1 7 7v7h26.08A6.038 6.038 0 0 1 67 36.08V23.92A6.038 6.038 0 0 1 62.08 19H25.92A6.038 6.038 0 0 1 21 23.92v12.16a6.386 6.386 0 0 1 1 .27V34a7.008 7.008 0 0 1 7-7zm11 5a1 1 0 0 1 2 0v.68a1.32 1.32 0 0 0 1 1.28v-3.24l-.73-.24a3.324 3.324 0 0 1 .73-6.46V23a1 1 0 0 1 2 0v1.02a3.322 3.322 0 0 1 3 3.3V28a1 1 0 0 1-2 0v-.68a1.32 1.32 0 0 0-1-1.28v3.24l.73.24a3.324 3.324 0 0 1-.73 6.46V37a1 1 0 0 1-2 0v-1.02a3.322 3.322 0 0 1-3-3.3z"
                        style={{ fill: "#ffffffe8" }} />
                    <path
                        d="M14 47a1.003 1.003 0 0 0 1 1h7v-9.44a3.906 3.906 0 0 0-2-.56 1.003 1.003 0 0 1-1-1V23a1.003 1.003 0 0 1 1-1 3.999 3.999 0 0 0 4-4 1.003 1.003 0 0 1 1-1h38a1.003 1.003 0 0 1 1 1 3.999 3.999 0 0 0 4 4 1.003 1.003 0 0 1 1 1v14a1.003 1.003 0 0 1-1 1 3.999 3.999 0 0 0-4 4 1.003 1.003 0 0 1-1 1H36v.38A11.865 11.865 0 0 1 42.76 48H73a1.003 1.003 0 0 0 1-1V14H14zM29 81a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1v-7H29z"
                        style={{ fill: "#ffffffe8" }} />
                </svg>
            </span>
        ),
        "Add Credit Debit" : (
            <span className="menu-icon" style={{ color: "white" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"  stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-credit-card">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"  />
                    <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z"  />
                    <path d="M3 10l18 0" />
                    <path d="M7 15l.01 0" />
                    <path d="M11 15l2 0" />
                </svg>
            </span>
        ),
        "View Outgoing": (
            <span className='menu-icon'>
                <span className='svg-icon svg-icon-2'>
                    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"
                        style={{
                            filRrule: "evenodd",
                            clipRule: "evenodd",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeMiterlimit: "2"
                        }}>
                        <path style={{ fill: "none" }} d="M0 0h64v64H0z" />
                        <path
                            d="M53.5 17.365c0-.754-.611-1.365-1.365-1.365h-29.27c-.754 0-1.365.611-1.365 1.365v36.223a1.366 1.366 0 0 0 2.068 1.171l4-2.4a1.366 1.366 0 0 1 1.24-.084l8.11 3.476c.369.158.789.145 1.148-.034l6.823-3.412a1.368 1.368 0 0 1 1.222 0l5.413 2.707a1.365 1.365 0 0 0 1.976-1.221V17.365z"
                            style={{
                                fill: "none",
                                stroke: "#ffffff",
                                strokeWidth: "4px"
                            }} />
                        <path
                            d="m21.5 45-1.692-.725a1.366 1.366 0 0 0-1.24.084l-4 2.4a1.366 1.366 0 0 1-2.068-1.171V9.365c0-.754.611-1.365 1.365-1.365h29.27c.754 0 1.365.611 1.365 1.365V12"
                            style={{
                                fill: "none",
                                stroke: "#ffffff",
                                strokeWidth: "4px"
                            }} />
                        <path
                            d="M40.524 20.713c1.98 1.499 1.966 4.859-.032 7.5-1.997 2.641-5.227 3.569-7.208 2.07-1.981-1.498-1.967-4.859.031-7.5s5.228-3.568 7.209-2.07z"
                            style={{
                                fill: "none",
                                stroke: "#ffffff",
                                strokeWidth: "4px"
                            }} />
                        <path
                            d="M33.284 30.283s.804-3.211 3.717-4.084c2.912-.874 3.102-5.758 3.102-5.758M32 37h11M32 42h11M32 47h11"
                            style={{
                                fill: "none",
                                stroke: "#ffffff",
                                strokeWidth: "4px"
                            }} />
                    </svg>
                </span>
            </span>
        ),
        "View Incomming": (
            <span className='menu-icon'>
                <span className='svg-icon svg-icon-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <defs>
                        </defs>
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="layer_1-2" data-name="layer 1">
                                <path className="cls-1"
                                    d="M20 48c-9.63 0-20-4.7-20-15 0-6.86 8-18.46 12.23-23.63l1.54 1.26C9 16.42 2 27.16 2 33c0 8.93 9.33 13 18 13 7.93 0 16.92-3.6 17.91-11.51l2 .25C38.76 43.85 28.78 48 20 48zM29.6 15.09a83.386 83.386 0 0 0-3.37-4.46l1.54-1.26c1.12 1.36 2.31 2.94 3.47 4.57z"
                                    style={{
                                        fill: "none",
                                        stroke: "#ffffff",
                                        strokeWidth: "2px"
                                    }} />
                                <path className="cls-1"
                                    d="M20 43c-9 0-14-3.55-14-10 0-4.67 4.52-14.15 8.23-18.64l1.54 1.28C12.35 19.79 8 28.79 8 33c0 6.61 6.53 8 12 8zM20 12c-2.95 0-5.91-.37-7.43-1.11a1 1 0 0 1-.52-.58l-3-9A1 1 0 0 1 10.52.14c4.07 2.46 14.89 2.46 19 0a1 1 0 0 1 1.15.08 1 1 0 0 1 .33 1.1l-3 9a1 1 0 0 1-.52.58C25.91 11.64 23 12 20 12zm-6.19-2.74c2.72 1 9.66 1 12.38 0l2.15-6.43a30.5 30.5 0 0 1-16.68 0zM36 31a4 4 0 0 1-4-4h2a2 2 0 1 0 2-2 4 4 0 1 1 4-4h-2a2 2 0 1 0-2 2 4 4 0 0 1 0 8z"
                                    style={{
                                        fill: "none",
                                        stroke: "#ffffff",
                                        strokeWidth: "2px"
                                    }} />
                                <path className="cls-1"
                                    d="M35 30h2v3h-2zM35 15h2v3h-2zM42 23h2v2h-2zM28 23h2v2h-2z"
                                    style={{
                                        fill: "none",
                                        stroke: "#ffffff",
                                        strokeWidth: "2px"
                                    }} />
                                <path className="cls-1"
                                    d="M25 41a.89.89 0 0 1-.36-.07A1 1 0 0 1 24 40V24a12 12 0 1 1 7 10.92l-5.28 5.76A1 1 0 0 1 25 41zm11-27a10 10 0 0 0-10 10v13.43L30.05 33a1 1 0 0 1 1.21-.2A10 10 0 1 0 36 14z"
                                    style={{
                                        fill: "none",
                                        stroke: "#ffffff",
                                        strokeWidth: "2px"
                                    }} />
                            </g>
                        </g>
                    </svg>

                </span>
            </span>
        ),
        "View Withdrawal": (
            <span className="menu-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98 98">
                    <rect x="26" y="64" width="28" height="8" rx="1" ry="1"
                        style={{ fill: "#ffffffe8" }} />
                    <path
                        d="M29.79 62H59v-5.51A11.108 11.108 0 0 0 62.81 50H41.65A10.036 10.036 0 0 0 34 45.05V34a5 5 0 0 0-10 0v17.24a11.046 11.046 0 0 0 2.72 7.25zM86 6H2a1.003 1.003 0 0 0-1 1v18a1.003 1.003 0 0 0 1 1h10v-6h-2a4 4 0 0 1 0-8h68a4 4 0 0 1 0 8h-2v6h10a1.003 1.003 0 0 0 1-1V7a1.003 1.003 0 0 0-1-1z"
                        style={{ fill: "#ffffffe8" }} />
                    <path
                        d="M8 16a2.006 2.006 0 0 0 2 2h2v-4h-2a2.006 2.006 0 0 0-2 2zM80 16a2.006 2.006 0 0 0-2-2h-2v4h2a2.006 2.006 0 0 0 2-2zM46 32.68a1.312 1.312 0 0 0-.91-1.26l-.09-.03v2.57a1.32 1.32 0 0 0 1-1.28zM43 26.04a1.323 1.323 0 0 0-.09 2.54l.09.03z"
                        style={{ fill: "#ffffffe8" }} />
                    <path
                        d="M29 27a7.008 7.008 0 0 1 7 7v7h26.08A6.038 6.038 0 0 1 67 36.08V23.92A6.038 6.038 0 0 1 62.08 19H25.92A6.038 6.038 0 0 1 21 23.92v12.16a6.386 6.386 0 0 1 1 .27V34a7.008 7.008 0 0 1 7-7zm11 5a1 1 0 0 1 2 0v.68a1.32 1.32 0 0 0 1 1.28v-3.24l-.73-.24a3.324 3.324 0 0 1 .73-6.46V23a1 1 0 0 1 2 0v1.02a3.322 3.322 0 0 1 3 3.3V28a1 1 0 0 1-2 0v-.68a1.32 1.32 0 0 0-1-1.28v3.24l.73.24a3.324 3.324 0 0 1-.73 6.46V37a1 1 0 0 1-2 0v-1.02a3.322 3.322 0 0 1-3-3.3z"
                        style={{ fill: "#ffffffe8" }} />
                    <path
                        d="M14 47a1.003 1.003 0 0 0 1 1h7v-9.44a3.906 3.906 0 0 0-2-.56 1.003 1.003 0 0 1-1-1V23a1.003 1.003 0 0 1 1-1 3.999 3.999 0 0 0 4-4 1.003 1.003 0 0 1 1-1h38a1.003 1.003 0 0 1 1 1 3.999 3.999 0 0 0 4 4 1.003 1.003 0 0 1 1 1v14a1.003 1.003 0 0 1-1 1 3.999 3.999 0 0 0-4 4 1.003 1.003 0 0 1-1 1H36v.38A11.865 11.865 0 0 1 42.76 48H73a1.003 1.003 0 0 0 1-1V14H14zM29 81a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1v-7H29z"
                        style={{ fill: "#ffffffe8" }} />
                </svg>
            </span>
        ),
        "View Credit" : (
            <span className="menu-icon" style={{ color: "white" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"  stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-credit-card">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"  />
                    <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z"  />
                    <path d="M3 10l18 0" />
                    <path d="M7 15l.01 0" />
                    <path d="M11 15l2 0" />
                </svg>
            </span>
        ),
        "View Debit" : (
            <span className="menu-icon" style={{ color: "white" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"  stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-credit-card">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"  />
                    <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z"  />
                    <path d="M3 10l18 0" />
                    <path d="M7 15l.01 0" />
                    <path d="M11 15l2 0" />
                </svg>
            </span>
        ),
    };

    const { isLoggedIn, manueList } = useContext(AppContext);
    const navigate = useNavigate();


    const logoutHandler = () => {
        Cookies.remove('token', { path: '/' });
        // setIsLoggedIn(false);
        toast.error("Logged out successfully. See you soon!", { position: "top-right" });
        navigate('/');
    }

    return (
        <div id='kt_aside' className='aside aside-dark aside-hoverable' data-kt-drawer='true'
            data-kt-drawer-name='aside' data-kt-drawer-activate='{default: true, lg: false}'
            data-kt-drawer-overlay='true' data-kt-drawer-width="{default:' 200px', '300px' : '250px' }"
            data-kt-drawer-direction='start' data-kt-drawer-toggle='#kt_aside_mobile_toggle'>
            <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
                <Link to='/dashboard'>
                    <img src='https://cqpplefitting.com/ad_cqpple/Admin/assets/LOGO/Png_UniqueIT-min.png'
                        className='img-fluid p-7' alt='Logo' />
                </Link>
                <div id="kt_aside_toggle" className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
                    <span className="svg-icon svg-icon-1 rotate-180">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.5" d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z" fill="black"></path>
                            <path d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z" fill="black"></path>
                        </svg>
                    </span>
                </div>
            </div>

            <div className='aside-menu flex-column-fluid'>
                <div className='hover-scroll-overlay-y my-5 my-lg-5' id='kt_aside_menu_wrapper' data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}' data-kt-scroll-height='auto'
                    data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
                    data-kt-scroll-wrappers='#kt_aside_menu' data-kt-scroll-offset='0'>
                    {/*    Menu    items    */}
                    <div className='menu menu-column pb-3 menu-state-title-primary menu-state-icon-primary 
                    menu-state-bullet-primary menu-arrow-gray-500'
                        id='#kt_aside_menu'
                        data-kt-menu='true'
                        data-kt-menu-expand='false'>
                        {manueList.map((item, index) => (
                            <div key={index} className='menu-item menu-accordion menu-state-title-primary menu-state-icon-primary 
                            menu-state-bullet-primary menu-arrow-gray-500'
                                id='#kt_aside_menu' data-kt-menu='true' data-kt-menu-expand='false'>
                                <div className='menu-item  menu-accordion'>
                                    <Link className="menu-link" to={`/${item.ManuRoute}`}>
                                        {IconMapper[item.ManueName] || "A  "}
                                        <span className="menu-title">{item.ManueName}</span>
                                    </Link>
                                </div>
                            </div>
                        ))}
                        {isLoggedIn &&
                            <div className='menu-item menu-accordion menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500'
                                id='#kt_aside_menu' data-kt-menu='true' data-kt-menu-expand='false' >
                                <div className='menu-item   menu-accordion'>
                                    <Link className="menu-link" to="/"
                                        onClick={logoutHandler}>
                                        <span className="menu-icon" style={{ color: "white" }} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-logout-2">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M10 8v-2a2 2 0 0 1 2 -2h7a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-2" />
                                                <path d="M15 12h-12l3 -3" />
                                                <path d="M6 15l-3 -3" />
                                            </svg>
                                        </span>
                                        <span className="menu-title">LogOut</span>
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
