import React from 'react'
import './topsidebar.css'

export const AmountCard = ({ name, amount, className }) => {
    return (
        <div className="info-container">
            <div className="row">
                <div className="col">
                    <h6>Name</h6>
                    <p className="name">{name}</p>
                </div>
                <div className="col text-end">
                    <h6>Amount</h6>
                    <p className={`amount ${className}`}>
                        {name === "Upwork" ? "$" : "₹"} {amount}
                    </p>
                </div>
            </div>
        </div>
    )
}
